import React from "react"

const IconButton = ({ attributes, onClick, icon }) => (
  <div
    {...attributes}
    className="btn-global"
    onClick={onClick}
    role="button"
    aria-label="Button"
    tabIndex="0"
  >
    {icon}
  </div>
)
export default IconButton
