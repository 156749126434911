import React, { useState } from "react"
import classnames from "classnames/bind"
import { Link, navigate } from "gatsby"
import Logo from "../../../assets/img/Logo.svg"
import IconButton from "../IconButton/IconButton"
import styles from "./Header.module.css"

const cx = classnames.bind(styles)

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <div className={styles.container}>
      <div
        className={cx("menu", {
          menuOpen,
        })}
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        <Link
          to="/#about"
          activeClassName={styles.menuItemActive}
          className={styles.menuItem}
        >
          ABOUT
        </Link>
        <Link
          to="/#how-it-works"
          activeClassName={styles.menuItemActive}
          className={styles.menuItem}
        >
          How it works
        </Link>
        <Link
          to="/designer"
          activeClassName={styles.menuItemActive}
          className={styles.menuItem}
        >
          For Designers
        </Link>
        <Link
          to="/#contact"
          activeClassName={styles.menuItemActive}
          className={styles.menuItem}
        >
          contact
        </Link>
      </div>
      <div className={styles.logo}>
        <IconButton
          icon={<Logo width="100%" height="100%" />}
          onClick={e => {
            e.preventDefault()
            navigate("/")
          }}
        />
      </div>
      <div
        className={cx("hamburger", { hamburgerClose: menuOpen })}
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyDown={() => {}}
        tabIndex={0}
        role="button"
      >
        <div />
        <div />
      </div>
    </div>
  )
}

export default Header
