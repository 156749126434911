import React from "react"
import styles from "./SocialMediaFooter.module.css"
import SocialFacebook from "../../../assets/img/social-facebook.svg"
import SocialTwitter from "../../../assets/img/social-twitter.svg"
import SocialLinkedin from "../../../assets/img/social-linkedin.svg"

const SocialMediaFooter = () => {
  return (
    <div className={styles.endWrapper}>
      <div className={styles.socialWrapper}>
        <div className={styles.socialBtn}>
          <a
            href="https://facebook.com/CreagentLtd"
            aria-label="Visit us on Facebook"
          >
            <SocialFacebook width="100%" height="100%" />
          </a>
        </div>
        <div className={styles.socialBtn}>
          <a
            href="https://twitter.com/Creagent_Ltd"
            aria-label="Visit us on Twitter"
          >
            <SocialTwitter width="100%" height="100%" />
          </a>
        </div>
        <div className={styles.socialBtn}>
          <a
            href="https://linkedin.com/company/creagent-ltd"
            aria-label="Visit us on Linked"
          >
            <SocialLinkedin width="100%" height="100%" />
          </a>
        </div>
      </div>
      <div className={styles.copyright}>
        Copyright 2019 © Creagent Ltd. All rights reserved.
      </div>
    </div>
  )
}

export default SocialMediaFooter
