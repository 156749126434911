/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react"
import styles from "./Button.module.css"

export default ({ text, disabled, onClick, color, style }) => (
  <div
    className={styles.container}
    onClick={onClick}
    style={{
      ...style,
      pointerEvents: disabled ? "none" : "auto",
      borderColor: color,
    }}
    role="button"
    aria-label={text}
  >
    {text}
  </div>
)
